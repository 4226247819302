import Matter from "matter-js";

const Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    Bodies = Matter.Bodies,
    Body = Matter.Body,
    Events = Matter.Events,
    Query = Matter.Query;

// Create engine
const engine = Engine.create(),
    world = engine.world;

// Get the canvas element and size it to the window
const canvas = document.getElementById('canvas');

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

let largerScreen = window.innerWidth > 700

// Create renderer
const render = Render.create({
  engine: engine,
  canvas: canvas,
  options: {
    width: window.innerWidth,
    height: window.innerHeight,
    wireframes: false,
    pixelRatio: 'auto',
    background: '#1E293B',
    // showAngleIndicator: true,
    // showDebug: true,
  }
});

Render.run(render);

// Create runner
const runner = Runner.create();
Runner.run(runner, engine);

// Randomly apply a force to all objects
const shakeScene = () => {
  let bodies = Composite.allBodies(world);

  for (let i = 0; i < bodies.length; i++) {
    let body = bodies[i];

    if (!body.isStatic && body.position.y >= 200) {
      let forceMagnitude = 0.02 * body.mass;

      Body.applyForce(body, body.position, { 
        x: (forceMagnitude + Common.random() * forceMagnitude) * Common.choose([1, -1]), 
        y: -forceMagnitude + Common.random() * -forceMagnitude
      });
    }
  }
};

// Add mouse control
const mouse = Mouse.create(render.canvas);

const mouseConstraint = MouseConstraint.create(engine, {
  mouse: mouse,
  constraint: {
    stiffness: 0.2,
    render: {
      visible: false,
    },
  },
});

Composite.add(world, mouseConstraint);

// Keep the mouse in sync with rendering
render.mouse = mouse;

// Update the walls on resize
const addOrUpdateWalls = () => {
  if (walls) {
    Composite.remove(world, walls);
  }

  let wallOptions = { isStatic: true, label: "wall", slop: 0, render: { visible: false } };
  let wallThickness = 500;
  let wallHeight = window.innerHeight * 10;

  walls = [
    // Floor
    Bodies.rectangle(
      window.innerWidth / 2,
      window.innerHeight + wallThickness / 2,
      window.innerWidth,
      wallThickness,
      wallOptions
    ),

    // Left wall
    Bodies.rectangle(
      -(wallThickness / 2),
      -(wallHeight / 2) + window.innerHeight + wallThickness,
      wallThickness,
      wallHeight,
      wallOptions
    ),

    // Right wall
    Bodies.rectangle(
      window.innerWidth + (wallThickness / 2),
      -(wallHeight / 2) + window.innerHeight + wallThickness,
      wallThickness,
      wallHeight,
      wallOptions
    ),
  ];

  Composite.add(world, walls);
};

// Set up initial walls
let walls = null;
addOrUpdateWalls();

// Get texture images (require triggers Parcel)
const textures = {
  name: require("./images/name.png"),
  founder: require("./images/founder.png"),
  designer: require("./images/designer.png"),
  developer: require("./images/developer.png"),
  twitter: require("./images/twitter.png"),
  linkedin: require("./images/linkedin.png"),
  inktrap: require("./images/inktrap.png"),
  emojiSmile: require("./images/emoji-smile.png"),
  emojiLaugh: require("./images/emoji-laugh.png"),
  emojiWink: require("./images/emoji-wink.png"),
  emojiUnsure: require("./images/emoji-unsure.png"),
  emojiSmirk: require("./images/emoji-smirk.png"),
  emojiThinking: require("./images/emoji-thinking.png"),
  emojiGrimace: require("./images/emoji-grimace.png"),
  emojiMonocle: require("./images/emoji-monocle.png"),
  emojiSandwich: require("./images/emoji-sandwich.png"),
}

// Set up and add main blocks
const windowCenter = window.innerWidth / 2

const blocks = {
  name: Bodies.rectangle(windowCenter - 20, -1000, 197, 70, {
    label: "name",
    restitution: 0.3,
    angle: 0.1,
    render: { sprite: { texture: textures.name, xScale: 0.5, yScale: 0.5 } },
  }),
  founder: Bodies.rectangle(windowCenter, -700, 312, 70, {
    label: "founder",
    restitution: 0.3,
    angle: -0.2,
    render: { fillStyle: "#000000", sprite: { texture: textures.founder, xScale: 0.5, yScale: 0.5 } },
  }),
  designer: Bodies.rectangle(windowCenter, -400, 262, 70, {
    label: "designer",
    restitution: 0.3,
    angle: 0.1,
    render: {
      sprite: { texture: textures.designer, xScale: 0.5, yScale: 0.5 },
    },
  }),
  developer: Bodies.rectangle(windowCenter, - 200, 275, 70, {
    label: "developer",
    restitution: 0.3,
    angle: -0.3,
    render: {
      sprite: { texture: textures.developer, xScale: 0.5, yScale: 0.5 },
    },
  }),
  twitter: Bodies.rectangle(windowCenter + 150, -1300, 70, 70, {
    label: "twitter",
    restitution: 0.3,
    angle: 0.1,
    render: {
      sprite: { texture: textures.twitter, xScale: 0.5, yScale: 0.5 },
    },
  }),
  linkedin: Bodies.rectangle(windowCenter + 150, -1200, 70, 70, {
    label: "linkedin",
    restitution: 0.3,
    angle: 0.2,
    render: {
      sprite: { texture: textures.linkedin, xScale: 0.5, yScale: 0.5 },
    },
  }),
  inktrap: Bodies.rectangle(windowCenter - 200, -1400, 70, 70, {
    label: "inktrap",
    restitution: 0.3,
    angle: 0,
    render: {
      sprite: { texture: textures.inktrap, xScale: 0.5, yScale: 0.5 },
    },
  }),
};

Composite.add(world, [blocks.name, blocks.founder, blocks.designer, blocks.developer, blocks.twitter, blocks.linkedin, blocks.inktrap]);

// Util to help pick random emojis
const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// Generate random emoji
if (largerScreen) {
  for (let i = 1; i < 21; i++) {
    let emojiTextures = [textures.emojiSmile, textures.emojiLaugh, textures.emojiWink, textures.emojiUnsure, textures.emojiSmirk, textures.emojiThinking, textures.emojiGrimace, textures.emojiMonocle, textures.emojiSandwich, textures.emojiSandwich]
  
    let selectedEmoji = emojiTextures[getRandomInt(0, emojiTextures.length - 1)]
    let emoji = null;
  
    // console.log(getRandomInt(0, emojiTextures.length - 1))
  
    if (selectedEmoji != textures.emojiSandwich) {
      emoji = Bodies.circle(Common.random(50, window.innerWidth - 50), Common.random(-4000, -2000), 25, {restitution: 0.8, render: {sprite: {texture: selectedEmoji, xScale: 0.5, yScale: 0.5}}});
    } else {
      emoji = Bodies.fromVertices(Common.random(50, window.innerWidth - 50), Common.random(-4000, -2000), [
        {x: 1.5, y: 17.5},
        {x: 21.5, y: 5},
        {x: 26, y: 5},
        {x: 48.5, y: 18.5},
        {x: 48.5, y: 34},
        {x: 28, y: 46.5},
        {x: 24, y: 46.5},
        {x: 1.5, y: 33},
      ], {
        label: "sandwich",
        render: {
          sprite: { texture: textures.emojiSandwich, xScale: 0.5, yScale: 0.5 },
        }
      })
    }
  
    Composite.add(world, emoji);
  }
}

// iOS Sandwich outline
// {x: 3, y: 10.5},
// {x: 32.5, y: 5},
// {x: 48, y: 17.5},
// {x: 48, y: 33},
// {x: 27.5, y: 39},
// {x: 18, y: 46.5},
// {x: 3, y: 28}

// Resize the canvas with the window
window.addEventListener('resize', () => { 
  render.options.width = window.innerWidth;
  render.options.height = window.innerHeight;
  render.canvas.width = window.innerWidth;
  render.canvas.height = window.innerHeight;

  Render.setPixelRatio(render, 'auto')

  largerScreen = window.innerWidth > 700

  addOrUpdateWalls()
});


// Change cursors on hover
Events.on(mouseConstraint, 'mousemove', function(event) {
  let bodies = Composite.allBodies(world);
  let hoveredBody = Query.point(bodies, event.mouse.position);

  if (hoveredBody.length > 0) {
    switch(hoveredBody[0]['label']) {
      case "inktrap":
      case "twitter":
      case "linkedin":
        document.body.setAttribute('style', 'cursor: pointer;')
        break;
      default:
        document.body.setAttribute('style', 'cursor: move;')
    }
  } else {
    document.body.removeAttribute('style')
  }
});

// Handle clicks and taps
const handleInteraction = (event) => {
  let bodies = Composite.allBodies(world)
  let interactedBody = Query.point(bodies, {x: event.pageX, y: event.pageY})

  if (interactedBody.length > 0) {
    switch(interactedBody[0].label) {
      case "inktrap":
        window.location = "https://www.inktrap.co.uk"
        break;
      case "twitter":
        window.location = "https://twitter.com/samlester"
        break;
      case "linkedin":
        window.location = "https://www.linkedin.com/in/samlester/"
        break;
    }
  } else {
    shakeScene()
  }
});

window.addEventListener('mousedown', handleInteraction, false)
window.addEventListener('touchstart', handleInteraction, false)

// Console log for devs
console.log("Hello developer! 👋 \n\nIn case you were wondering, the physics stuff is created using Matter JS (https://brm.io/matter-js/)")
